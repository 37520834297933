.project-container {
  margin-top: 50px; margin-bottom: 50px;
  margin-left: 200px; margin-right: 200px;
}

.p-body {
  display: flex;
  flex-direction: column;
  align-items:  left;
  margin-bottom: 100px;
}

.p-body h2 {
  padding: 1rem 0;
  font-weight: bold;
  margin-bottom: 0;
}

.title {
  display: flex;
  align-items: center;
}

.p-body h2:hover {
  color: rgb(43, 157, 185);
}

.one-img {
  object-fit: contain;
  max-width: 100%;
  margin-top: 50px;
}

.two-img img {
  object-fit: contain;
  max-width: 100%;
  margin-top: 50px;
}

@media (max-width: 768px) {
  .project-container {
    margin-left: 5px; margin-right: 5px;
  }  
}