.about-container {
  margin-top: 50px; margin-bottom: 50px;
  margin-left: 450px; margin-right: 450px;
} 

.about-container p {
  text-indent: 45px;
}

@media screen and (max-width: 768px) {
  .about-container {
    margin-left: 5px; margin-right: 5px;
  }
  
}