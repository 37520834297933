body {
  margin: 0;
  padding: 0;
  background-color: rgb(61, 60, 60);
  color: whitesmoke;
}

body * {
  font-family: 'Roboto', sans-serif;
  text-decoration: none; 
  line-height: 2;
}

ul {
  list-style: none;
}

.container-fluid {
  padding: 0;
}

.top-page {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 90vh;
}

.top-page span {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url(../../public/Images/background.png);
  background-size: cover;
  filter: blur(5px);
  z-index: -1;
}

.top-page p {
  text-align: center;
  font-size: 90px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: bold;
}

.scroll-banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgb(43, 157, 185);
  height: 80px;
}

.scroll-banner h2 {
  font-weight: bold;
  margin: 0;
}

.scroll-banner hr {
  margin: 0;
}

.scroll-banner div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

@media (max-width: 768px) {
  .top-page p {
    font-size: 40px; 
  }
}

