.resume-container {
  margin-top: 50px; margin-bottom: 50px;
  margin-left: 200px; margin-right: 200px;
}

.resume-container img {
  border-style: solid; border-color: rgb(43, 157, 185); 
  border-width: 5px; border-radius: 5px;
  max-width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .resume-container {
    margin-left: 5px; margin-right: 5px;
  }  
}